<template>
  <div class="invoice-detail">
    <content-block title="物流信息">
      <el-descriptions :column="3" size="normal">
        <el-descriptions-item label="物流公司">{{ info.logistics | setEmpty }}</el-descriptions-item>
        <el-descriptions-item label="物流单号">{{ info.oddNumbers | setEmpty }}</el-descriptions-item>
      </el-descriptions>
    </content-block>
    <content-block title="发货物资信息">
      <TableWithPagination :columnList="columnList" :tableData="info.sellerNoticeDetailsRepDtoBuilderList" />
    </content-block>
    <content-block title="收货人信息">
      <el-descriptions :column="3" size="normal">
        <el-descriptions-item label="姓名">{{ info.receivingInfo.receivingName | setEmpty }}</el-descriptions-item>
        <el-descriptions-item label="联系电话">{{ info.receivingInfo.receivingPhone | setEmpty }}</el-descriptions-item>
        <el-descriptions-item label="地址">{{ info.receivingInfo.detailAddress | setEmpty }}</el-descriptions-item>
      </el-descriptions>
    </content-block>
    <content-block title="物流轨迹">
      <el-collapse accordion v-model="activeName">
        <el-collapse-item
          v-for="(i, index) in info.transPortLocusList"
          :title="`快件${index + 1}`"
          :key="i.transportBoxId"
          :name="index"
        >
          <el-timeline>
            <el-timeline-item v-for="(item, index) in i.kuaiDiRecordRes" :key="index" :timestamp="item.recordTime">
              <el-card>
                <p class="recordItem">
                  {{ item.context.indexOf('null') !== -1 ? '快件' : item.context }} {{ item.status }}
                </p>
                <p v-if="item.signName" class="recordItem">签收人：{{ item.signName }}</p>
                <p v-if="item.signMobile" class="recordItem">签收人电话：{{ item.signMobile }}</p>
                <p v-if="item.signAttachment" class="recordItem">
                  签收附件：
                  <el-image
                    :preview-src-list="[item.signAttachment]"
                    :src="item.signAttachment"
                    style="width: 100px; height: 100px"
                  ></el-image>
                </p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </el-collapse-item>
      </el-collapse>
    </content-block>
    <content-block title="附件信息">
      <el-form inline>
        <el-form-item label="随货同行单">
          <upload
            :readonly="!checkStateFlag"
            v-model.trim="attchList"
            label="标准协议"
            :limit="10"
            :max-size="5"
            size="small"
            accept="image/jpeg,image/jpg,image/png,image/webp"
          />
        </el-form-item>
      </el-form>
    </content-block>
    <el-row v-if="checkStateFlag" class="content_block" type="flex" justify="center">
      <el-button @click="cancelAttchHandle" size="small">取消</el-button>
      <el-button type="primary" size="small" @click="subAttchHandle">提交</el-button>
    </el-row>
  </div>
</template>
<script>
import TableWithPagination, { TablePaginationMixin } from '@/components/common/TableWithPagination';
import { details, modifyDetails } from '@/api/invoice';
import * as Moment from 'dayjs';

export default {
  name: 'invoice_detail',
  mixins: [TablePaginationMixin],
  components: {
    TableWithPagination,
  },
  props: {
    id: {
      type: String,
    },
  },
  filters: {
    setEmpty (val) {
      return val ? val : '--';
    },
  },
  data () {
    return {
      attchList: [],
      checkStateFlag: false,
      activeName: 0,
      info: {
        receivingInfo: {},
      },
      columnList: [
        {
          label: '商品编码',
          prop: 'goodsCode',
          tooltip: true,
        },
        {
          label: '商品名称',
          prop: 'goodsName',
          tooltip: true,
        },
        {
          label: '品牌名称',
          prop: 'brandName',
          tooltip: true,
        },
        {
          label: '规格',
          prop: 'specification',
          tooltip: true,
        },
        {
          label: '型号',
          prop: 'model',
          tooltip: true,
        },
        {
          label: '生产商',
          prop: 'producer',
          tooltip: true,
        },
        {
          label: '厂家编码',
          prop: 'factoryCode',
          tooltip: true,
        },
        {
          label: '发货数量',
          prop: 'currentArrivalNum',
          tooltip: true,
        },
        {
          label: '生产日期',
          prop: 'productionDate',
          tooltip: true,
          render: (h, { row }) => {
            return h('span', this.formateDate(row['productionDate'], 'YYYY-MM-DD'));
          },
        },
        {
          label: '失效日期',
          prop: 'expiryDate',
          tooltip: true,
          render: (h, { row }) => {
            return h('span', this.formateDate(row['expiryDate'], 'YYYY-MM-DD'));
          },
        },
        {
          label: '生产批次号',
          prop: 'productionBatchNumber',
          tooltip: true,
        },
        {
          label: '生产序列号',
          prop: 'productionSerialNumber',
          tooltip: true,
        },
        {
          label: '是否医疗件',
          prop: 'isMedichair',
          tooltip: true,
          render: (h, { row }) => {
            return h('span', row.isMedichair === 1 ? '是' : '否');
          },
        },
        {
          label: '注册证号',
          prop: 'regNo',
          tooltip: true,
        },
      ],
    };
  },
  created () {
    this.getDetail();
  },
  methods: {
    cancelAttchHandle () {
      this.$router.push({name: 'InvoiceMgr'});
    },
    subAttchHandle () {
      // if (this.info.sellerNoticeDetailsRepDtoBuilderList.length && !!this.info.sellerNoticeDetailsRepDtoBuilderList.find(i => i.isMedichair === 1) && !this.attchList.length) {
      //   return this.$message.error('发货单中含有医疗件，请上传随货同行单');
      // }
      const param = {
        accompanyBillList: this.attchList.map((i) => ({ annexName: i.attachName, annexUrl: i.url })),
        id: this.id,
      };
      modifyDetails(param).then(() => {
        this.$message.success('修改附件成功');
        this.cancelAttchHandle();
      });
    },
    formateDate (date, format) {
      if (!date) {
        return '--';
      } else {
        return Moment(date).format(format);
      }
    },
    getDetail () {
      details({ id: this.id }).then(({ body }) => {
        let { receivingInfo, accompanyBilList, checkState } = body;
        receivingInfo.detailAddress
            = `${receivingInfo.receivingProvinceName || ''}${receivingInfo.receivingCityName || ''}${
            receivingInfo.receivingAreaName || ''
          }${receivingInfo.receivingAddrs || ''}` || '--';

        body.transPortLocusList = body.transPortLocusList || [];
        this.attchList = accompanyBilList.map((i) => ({
          id: i.id,
          attachName: i.annexName,
          url: i.annexUrl,
        }));
        this.checkStateFlag = checkState === 'NO';
        this.info = body;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  .invoice-detail {
    .common-font {
      color: #909399;
      font-size: 14px;
      font-weight: 500;
    }
  }
</style>
